import React, {useEffect, useState} from "react";
import CustomModalWindow from "./CustomModalWindow";
import Select from "react-select";
import {FormGroup} from "reactstrap";
import DatePicker, {registerLocale} from "react-datepicker";
import {maskedDateInput} from "../Input/MaskedTextInput";
import "../Input/maskedTextInput.css";
import ru from "date-fns/locale/ru";
import {SUPPORTS, TEACHERS} from "../../constants";
import {useDispatch, useSelector} from "react-redux";
import {replaceTeacherRequest} from "../../store/acrions/user/actionsCreators";
import moment from "moment";
import Alert from "reactstrap/es/Alert";
import {cleanUpReplaceTeacherErr} from "../../store/acrions/user/actions";
import Button from "reactstrap/es/Button";

const ReplaceTeacherModal = ({toggle, modal, nameModal, users, valueModal}) => {
    const dispatch = useDispatch();

    const currentDate = new Date();
    const startOfTheMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    const endOfTheMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
    registerLocale("ru", ru);

    const replacementError = useSelector(state => state.user.replaceTeacherError);

    const [teachers, setTeachers] = useState([]);
    const [supports, setSupports] = useState([]);
    const [error, setError] = useState("");
    const [isPeriod, setIsPeriod] = useState(false);
    const [mondayThursday, setMondayThursday] = useState(false);
    const [tuesdayFriday, setTuesdayFriday] = useState(false);
    const DATE_LENGTH = 6;

    const [replaceTeacher, setReplaceTeacher] = useState({
        teacher: valueModal.id,
        support: null,
        comment: null,
        group: null,
        date_start: {
            replacement_date: null,
            invalid: false,
            datepickerCssClass: "empty-background",
        },
        date_end: {
            replacement_date: null,
            invalid: false,
            datepickerCssClass: "empty-background",
        }
    });

    const replaceCanHappen = !(replaceTeacher.teacher &&
        replaceTeacher.support &&
        replaceTeacher.date_start.replacement_date &&
        (isPeriod ? ((mondayThursday || tuesdayFriday) && replaceTeacher.date_end.replacement_date):
            replaceTeacher.date_start.replacement_date));

    useEffect(() => {
        users.map((u, i) => {
            const role = u.groups.find(data => {
                return data.name === TEACHERS || data.name === SUPPORTS;
            });
            if (role !== undefined) {
                if (role.name === TEACHERS) {
                    teachers.push({
                        value: u.id,
                        label: u.last_name + " " + u.first_name
                    });
                    if (u.id !== valueModal.id) {
                        supports.push({
                            value: u.id,
                            label: u.last_name + " " + u.first_name
                        });
                    }
                }
                if (role.name === SUPPORTS) {
                    supports.push({
                        value: u.id,
                        label: u.last_name + " " + u.first_name
                    });
                }
            }
        });
    }, []);

    const teacherHandler = value => {
        setReplaceTeacher({
            ...replaceTeacher, teacher: value.value
        });
    };

    const supportHandler = value => {
        setReplaceTeacher({
            ...replaceTeacher, support: value.value
        });
    };

    const replaceDateHandler = (value, datepicker_name) => {
        if ((value || !value) && datepicker_name === "date_end" && !replaceTeacher.date_start.replacement_date
            || datepicker_name === "date_start" && !value) {
            setReplaceTeacher({
                ...replaceTeacher,
                [datepicker_name]: {
                    replacement_date: null,
                    invalid: true,
                    datepickerCssClass: "in-valid-background",
                }
            });
            setError("Дата начала не может быть пустой");
        } else if (value < startOfTheMonth || value.length < DATE_LENGTH) {
            setReplaceTeacher({
                ...replaceTeacher,
                [datepicker_name]: {
                    replacement_date: null,
                    invalid: true,
                    datepickerCssClass: "in-valid-background",
                }
            });
            setError("Дата не может быть раньше начала месяца и должна быть из 6 символов");
        } else if (value && datepicker_name === "date_end" && replaceTeacher.date_start.replacement_date &&
            value < replaceTeacher.date_start.replacement_date) {
            setReplaceTeacher({
                ...replaceTeacher,
                [datepicker_name]: {
                    replacement_date: null,
                    invalid: true,
                    datepickerCssClass: "in-valid-background",
                }
            });
            setError("Дата окончания не может быть раньше даты начала");
        } else if (value && datepicker_name === "date_start" && replaceTeacher.date_end.replacement_date &&
            value > replaceTeacher.date_end.replacement_date) {
            setReplaceTeacher({
                ...replaceTeacher,
                [datepicker_name]: {
                    replacement_date: null,
                    invalid: true,
                    datepickerCssClass: "in-valid-background",
                }
            });
            setError("Дата начала не может быть позже даты окончания");
        } else {
            setReplaceTeacher({
                ...replaceTeacher,
                [datepicker_name]: {
                    replacement_date: value,
                    invalid: false,
                    datepickerCssClass: "valid-background"
                }
            });
        }
    };


    const changeCommentHandler = (e) => {
        setReplaceTeacher({...replaceTeacher, comment: e.target.value});
    };

    const replaceToggle = () =>{
        dispatch(cleanUpReplaceTeacherErr());
        toggle("replace", false);
    };


    const submitRequest = async (e) => {
        e.preventDefault();
        let daysOfWeek = {
            "mondayThursday": mondayThursday,
            "tuesdayFriday": tuesdayFriday
        };
        const data = {
            teacher: replaceTeacher.teacher,
            support: replaceTeacher.support,
            comment: replaceTeacher.comment,
            replacement_date_start: moment(replaceTeacher.date_start.replacement_date).format("YYYY-MM-DD"),
            replacement_date_end: replaceTeacher.date_end.replacement_date ? moment(replaceTeacher.date_end.replacement_date).format("YYYY-MM-DD") : null,
            daysOfWeek: daysOfWeek
        };
        dispatch(cleanUpReplaceTeacherErr());
        dispatch(replaceTeacherRequest(data));
    };

    return replaceTeacher && (
        <div>
            <CustomModalWindow
                toggle={replaceToggle}
                modal={modal}
                nameModal={nameModal}
                close={"Отмена"}
                confirm={"Сохранить"}
                button_color={"btn btn-info"}
                onClick={submitRequest}
                title={"Замена преподавателя"}
                disabled={replaceCanHappen}
            >
                <FormGroup>
                    <label>Заменить:</label>
                    <div id='select-teacher'>
                        <Select
                            placeholder=''
                            defaultValue={{
                                label: valueModal.last_name + " " + valueModal.first_name,
                                value: valueModal.id
                            }}
                            options={teachers}
                            onChange={teacherHandler}
                            isDisabled={true}
                        />
                        <br/>
                    </div>

                    <label>На:</label>
                    <div id='select-support'>
                        <Select
                            placeholder=''
                            options={supports}
                            onChange={supportHandler}
                        />
                        <br/>
                    </div>

                    <label>Дата {isPeriod ? "с" : ""}:</label>
                    <div id='replacement-date'
                         className={replaceTeacher.date_start.datepickerCssClass}>
                        <DatePicker
                            onChange={(v) => (replaceDateHandler(v, "date_start"))}
                            selected={replaceTeacher.date_start.replacement_date}
                            minDate={startOfTheMonth}
                            maxDate={!replaceTeacher.date_end.replacement_date ? endOfTheMonth: replaceTeacher.date_end.replacement_date}
                            isClearable={true}
                            dateFormat="dd.MM.yyyy"
                            locale='ru'
                            placeholderText="ДД.ММ.ГГГГ"
                            showMonthDropdown
                            showYearDropdown
                            customInput={maskedDateInput()}
                        />
                    </div>
                    {replaceTeacher.date_end.invalid || replaceTeacher.date_start.invalid ?
                        <div style={{
                            fontSize: "13px",
                            color: "#DC3556"
                        }}>{error}</div> : null}
                    {isPeriod ?
                        <div>
                            <label style={{"display": "block"}}>Дата до:</label>
                            <div id='replacement-date'
                                 className={replaceTeacher.date_end.datepickerCssClass}>
                                <DatePicker
                                    onChange={(v) => (replaceDateHandler(v, "date_end"))}
                                    selected={replaceTeacher.date_end.replacement_date}
                                    minDate={!replaceTeacher.date_start.replacement_date ? startOfTheMonth: replaceTeacher.date_start.replacement_date}
                                    maxDate={endOfTheMonth}
                                    isClearable={true}
                                    dateFormat="dd.MM.yyyy"
                                    locale='ru'
                                    placeholderText="ДД.ММ.ГГГГ"
                                    showMonthDropdown
                                    showYearDropdown
                                    customInput={maskedDateInput()}
                                />
                            </div>
                            <div className="custom-control custom-checkbox">
                                <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id="monThu"
                                    onChange={() => setMondayThursday(!mondayThursday)}
                                    checked={mondayThursday}
                                />
                                <label
                                    className="custom-control-label"
                                    htmlFor="monThu"
                                >Понедельник/Четверг</label>
                                <hr/>
                            </div>
                            <div className="custom-control custom-checkbox">
                                <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id="tueFri"
                                    onChange={() => setTuesdayFriday(!tuesdayFriday)}
                                    checked={tuesdayFriday}
                                />
                                <label
                                    className="custom-control-label"
                                    htmlFor="tueFri"
                                >Вторник/Пятница</label>
                            </div>
                            <Button onClick={() => {
                                setIsPeriod(false);
                                setReplaceTeacher({
                                    ...replaceTeacher,
                                    ["date_end"]: {
                                        replacement_date: null,
                                        invalid: false,
                                        datepickerCssClass: "empty-background",
                                    }
                                });
                                setTuesdayFriday(false);
                                setMondayThursday(false);
                            }

                            }>Заменить на один день</Button>

                        </div> : <Button onClick={() => setIsPeriod(true)}>Заменить на период</Button>}
                </FormGroup>
                <FormGroup>
                    <label>Комментарий:</label>
                    <textarea
                        className='form-control'
                        onChange={changeCommentHandler}
                    />
                </FormGroup>
                {replacementError && <Alert color="danger">{replacementError}</Alert>}
            </CustomModalWindow>
        </div>
    );
};

export default ReplaceTeacherModal;
