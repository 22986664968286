import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {fetchUsers} from "../../store/acrions/user/actionsCreators";
import ReplaceTeacherModal from "../../components/Modals/ReplaceTeacherModal";
import Button from "reactstrap/es/Button";
import LogsOfReplacement from "../Logs/LogsTables/LogsOfReplacement";
import {getLogs} from "../../store/acrions/Log/actionsCreators";
import {REPLACEMENTS} from "../../constants";
import {replace_modal_status} from "../../store/acrions/user/actions";

const FIRST_DATE_OF_MONTH = 1;

const ReplaceTeacherComponent = () => {
    const currentDate = new Date();
    const [date, setDate] = useState(new Date(currentDate.getFullYear(), currentDate.getMonth(), FIRST_DATE_OF_MONTH));
    const dispatch = useDispatch();
    let usersData = useSelector(state => state.user.users);
    let user = useSelector(state => state.user.user);
    const logs = useSelector(state => state.logs.logs);
    const replace = useSelector(state => state.user.replaceModalIsOpen);
    const toggle = (name, data) => {
        dispatch(replace_modal_status());
    };


    const [valueModal, setValueModal] = useState({});
    let logs_data = logs?.logs;
    useEffect(() => {
        dispatch(fetchUsers());
        dispatch(getLogs(REPLACEMENTS, date.getFullYear()));
        const user_data = {
            id: user.user.id,
            first_name: user.user.first_name,
            last_name: user.user.last_name,
            role: user.user.groups,
            right: user.user.additional_group
        };
        setValueModal(user_data);
    }, [dispatch, replace]);

    return (
        <div className='contacts-wrap pl-lg-3 pr-lg-3 content'>
            <Button
                onClick={() => toggle()}
            >
                Осуществить замену
            </Button>
            {logs_data ? <LogsOfReplacement logs={logs_data}/>:null}
            {replace && usersData ? <ReplaceTeacherModal
            toggle={toggle}
            modal={replace}
            nameModal={"replace"}
            users={usersData}
            valueModal={valueModal}
        /> : null}
        </div>
    );
};

export default ReplaceTeacherComponent;
